
import ButtonControl from '@/components/js/ButtonControl'
import GroupBtnControl from '@/components/js/GroupBtnControl';
import useQuery from '@/composables/dom/useQuery';
import { useI18n } from 'vue-i18n';

export default function mapBoxControl() {
    const { t } = useI18n();
    const { getQuery } = useQuery();

    const listControl = new ButtonControl({
        buttonStyle: "square",
        text: t('map.guide'),
        src: require('@/assets/img/icons/list.svg'),
        canActive: true
    });


    const locateControl = new ButtonControl({
        buttonStyle: "circle",
        src:  require("@assets/img/icons/location.svg"),
        containerClass: "mapboxgl-ctrl-bottom",
    });

    const tsunamiControl = new ButtonControl({
        buttonStyle: "square",
        text: t('map.harzard'),
        src: require('@assets/img/icons/harzard.svg'),
    })

    const shelterControl = new ButtonControl({
        buttonStyle: "square",
        text: t("map.shelter"),
        canActive: true,
        src: require('@assets/img/icons/run.svg'),
    })

    let threeGroupButtons = [];

    if (getQuery('water-level') == 3) {
        threeGroupButtons = [
            {
                id: 'shelter',
                radiusStyle: 'top',
                text: t("map.shelter"),
                src: require('@assets/img/icons/run.svg'),
            },
            {
                id: 'waterlevel',
                radiusStyle: 'bottom',
                text: t('map.waterLevel'),
                src: require('@assets/img/icons/water_level.svg'),
            }
        ];
    } else {
        threeGroupButtons = [
            {
                id: 'shelter',
                radiusStyle: 'top',
                text: t("map.shelter"),
                src: require('@assets/img/icons/run.svg'),
            },
            {
                id: 'congestion',
                radiusStyle: 'normal',
                text: t("map.congestion"),
                src: require('@assets/img/icons/group.svg'),
            },
            {
                id: 'waterlevel',
                radiusStyle: 'bottom',
                text: t('map.waterLevel'),
                src: require('@assets/img/icons/water_level.svg'),
            }
        ];
    }

    const threeGroupControl = new GroupBtnControl({
        buttons: threeGroupButtons,
        class: "mt-8",
        canActive: true
    })

    const twoGroupControl = new GroupBtnControl({
        buttons: [
            {
                id: 'tsunami',
                radiusStyle: 'top',
                text: t('map.tsunami'),
                src: require('@assets/img/icons/tsunami.svg'),
            },
            {
                id: 'liquefaction',
                radiusStyle: 'bottom',
                text: t("map.risk"),
                src: require('@assets/img/icons/liquefaction.svg'),
            },
        ],
        class: "mt-8",
    })

    return {
        listControl,
        shelterControl,
        locateControl,
        tsunamiControl,
        twoGroupControl,
        threeGroupControl,
    };
}