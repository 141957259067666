const components = require.context("./ui", true, /\.(vue)$/i);
const jsComponents = require.context("./js", true, /\.(js)$/i);
const directives = require.context("./directive", true, /\.(js)$/i);
export default (app) => {
    const mixins = app._context.mixins[0].methods;
    components
        .keys()
        .forEach((k) =>
            app.component(
                mixins.toKebabCase(mixins.getFileNameFromPath(k)),
                components(k).default
            )
        );
    jsComponents
        .keys()
        .forEach((k) =>
            app.component(
                mixins.toKebabCase(mixins.getFileNameFromPath(k)),
                jsComponents(k).default
            )
        );
    directives
        .keys()
        .forEach((k) =>
            app.directive(
                mixins.toKebabCase(mixins.getFileNameFromPath(k)),
                directives(k).default
            )
        );
};
