export default {
    map_tag: "地図",
    harzard: 'ハザード',
    harzard_map_tag: "竹芝防災",
    tsunami: "津波",
    flood: "洪水",
    guide: "凡例",
    shelter: "避難先",
    congestion: "混雑",
    waterLevel:"水位",
    liquefaction: "液状化",
    risk: "リスク",
    climax: "高潮",
    bubble: {
        storm: {
            _: "高潮浸水想定区域（想定最大規模）",
        },
        flood: {
            _: "洪水浸水想定区域（想定最大規模）",
        },
        tsunami: {
            _: "津波リスク",
        },
        liquefaction: {
            _: "液状化のリスク",
            high: "液状化の可能性が高い",
            normal: "液状化の可能性がある",
            low: "液状化の可能性が低い",
            question: "液状化リスクとは",
            answer: "歩行不可能な状態"
        },
        risk: {
            _: "表示するハザードマップを選択",
            content: "地震が起きた場合のリスクを確認できます"
        },
        waterLevel: {
            title: "堤防を越えるまで：あと{level}{format}",
            title_2: "河川水位が堤防を越えている可能性があります。",
            content: "観測地点：新浜橋"
        },
        manholeLevel: {
            title: "マンホールの現在の水位：地上まで {level}{format}",
            title_2: "マンホールから下水が溢れている可能性があります。\n最大浸水想定：{level}{format}",
            content: "観測地点：芝商業高校前"
        },
        congestion: {
            title: "道の混雑状況",
            content: "浜松町付近：大混雑発生"
        },
        levelRange: "{min}{format}以上〜{max}{format}未満"
    },
    carousel: {
        distance: "現在地から",
        status: "開設状況　",
        opening: "収容状況　"
    },
    emergency: {
        manhole: `浸水が発生している可能性があります。
            直ちになるべく高い場所に避難しましょう。`,
        river: `河川が氾濫している可能性があります。
            直ちになるべく高い場所に避難しましょう。`,
        congestion: `液状化や混雑が発生しています。
            危険を避けて避難して下さい。`,
        no_flood_congestion: `混雑・冠水は発生していません`,
        no_congestion: `混雑は発生していません`,
        no_flood: `冠水は発生していません`
    },
    permissionNotify: "現在地表示ボタンを利用するには、LINEアプリの位置情報の許可が必要です。",
    risk_popup: {
        flood: '洪水による浸水リスクあり。\nなるべく高く安全な場所に避難してください。',
        storm: '高潮による浸水リスクあり。\nなるべく高く安全な場所に避難してください。',
        liquefaction: '地震時に液状化が発生し地面が崩れるリスクがあります。',
        tsunami: '地震時に津波が発生するリスクがあります。'
    }
};