<template>
    <div
        :id="name || 'carousel'"
        class="carousel-bottom-container"
        v-if="!isClosed"
    >
        <div
            v-for="(carousel, index) in data"
            :id="name + '-' + carousel.shelter_id"
            :key="index"
            :class="
                'carousel-card' +
                (index === 0 ? ' carousel-card-start' : '') +
                (carousel.isActive ? ` carousel-card-active` : '')
            "
            @click="onCarouselClick(carousel.shelter_id)"
        >
            <div class="carousel-card-body">
                <div class="carousel-card-tag-area">
                    <div
                        v-for="(tag, i) in carousel.tags"
                        :key="i"
                        :class="'carousel-card-tag ' + getTagColor(tag)"
                    >
                        {{ tag.name }}
                    </div>
                </div>
                <div class="carousel-card-title">{{ carousel.name }}</div>
                <div class="carousel-card-subtitle">
                    {{ carousel.description }}
                </div>
                <div class="carousel-card-content">
                    {{ $t("map.carousel.status") }}
                    <span class="color-grey">{{ getStatus(carousel.available_status) }}</span>
                </div>
                <div class="carousel-card-content">
                    {{ $t("map.carousel.opening") }}
                    <span :class="getOpeningColor(carousel.containment_status)">{{ (carousel.containment_status || '-') }}</span>
                </div>
                <div class="carousel-card-image">
                    <img :src="carousel.image_path" @error="(e) => e.target.src = '/img/shelters/noimage.png'">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { nextTick, watch } from "@vue/runtime-core";
import useQuery from "@/composables/dom/useQuery";


export default {
    name: "CarouselPopup",
    props: {
        name: {
            type: String,
            default: "carousel",
        },
        data: {
            type: Array,
            default() {
                return [
                    {
                        id: "",
                        title: "",
                        subtitle: "",
                        isActive: "",
                        distance: "",
                        status: "",
                        imageURL: "",
                        tags: [
                            {
                                text: "",
                                color: "",
                            },
                        ],
                    },
                ];
            },
        },
        selected: {
            type: String,
            default: null,
        },
    },
    emits: ['on-select'],
    setup(props, {emit}) {
        let isClosed = ref(true);
        let lastSelected = ref(0);

        const { replaceQuery } = useQuery();

        const onClose = () => {
            isClosed.value = true;
        }

        const onOpen = () => {
            isClosed.value = false;

            nextTick(() => {
                onSelect(lastSelected.value);
            })
        }

        const getStatus = (status) => {
            return status ? "開設済" : "未開設";
        }

        const getTagColor = (tag) => {
            if (!tag) {
                return;
            }

            if (tag.name === '津波避難ビル') {
                return "bg-purple"
            }

            return 'bg-green';
        }

        const getOpeningColor = (openingStatus) => {
            const STATUS_AVAILABLE = "空きあり";
            const STATUS_UNAVAILABLE = "混雑";

            if (openingStatus === STATUS_AVAILABLE) {
                return 'color-aqua';
            }

            if (openingStatus === STATUS_UNAVAILABLE) {
                return 'color-danger';
            }

            return 'color-grey';
        }

        const removeSelection = () => {
            const activeClass = "carousel-card-active";
                
            for (let i = 0; i < props.data.length; i++) {
                let element = document.getElementById(
                    props.name + "-" + props.data[i].shelter_id
                );
                if (!element) return;

                if (element.classList.contains(activeClass)) {
                    element.classList.remove(activeClass);
                }
            }
        }

        const onCarouselClick = (index) => {
            const selectIndex = index || props.selected;
            replaceQuery({shelter_id: selectIndex});
            emit('on-select', selectIndex);
        }

        const onSelect = (index = null) => {
            const selectIndex = index || props.selected;

            if (!selectIndex) {
                return;
            }

            isClosed.value = false;
            lastSelected.value = selectIndex;
            nextTick(() => {
                removeSelection();
                let element = document.getElementById(
                    props.name + "-" + selectIndex
                );
                if (element) {
                    element.classList.add("carousel-card-active");
                    element.scrollIntoView({
                        behavior: "auto",
                        block: "center",
                        inline: "center",
                    });
                }
            });
        }

        watch(
            () => props.selected,
            () => onSelect()
        );

        return {
            isClosed,
            onClose,
            onOpen,
            onSelect,
            onCarouselClick,
            removeSelection,
            getStatus,
            getTagColor,
            getOpeningColor,
        };
    },
};
</script>