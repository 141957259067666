import axios from "axios";
import * as qs from "qs";

const methods = ["post", "put", "patch", "options"];
const http = axios.create({
    headers: {
        Accept: "application/json",
        "X-Service-Key": process.env.VUE_APP_API_SERVICE_KEY
    },
});
// request interceptor
http.interceptors.request.use(
    async (config) => {
        if (
            methods.includes(config.method) &&
            config.data &&
            !(config.data instanceof FormData) &&
            config.headers["Content-Type"] ===
                "application/x-www-form-urlencoded"
        ) {
            config.data = qs.stringify(config.data);
        }
        config.baseURL = config.baseURL || process.env.VUE_APP_API_BASE_URL;
        return config;
    },
    (e) => {
        return Promise.reject(e);
    }
);

// response interceptor
http.interceptors.response.use(
    (res) => {
        if (res.status === 200 || res.status === 201) {
            return Promise.resolve(res.data);
        }
        return Promise.reject(res);
    },
    (e) => {
        // handle error by status code
        return Promise.reject(e.response || e);
    }
);

export default http;
