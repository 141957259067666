

import { ref } from "vue";

export default function mapBoxZone() {
    let map = ref(null);
    let zones = ref([]);
    let showZones =  ref(false);

    const addZone = (id, lngLatLists, color = "#0000ff", opacity = 0.5, geometryType = "Polygon") => {
        map.value.on("style.load", () => {
            map.value.addSource('zone-' + id, {
                type: "geojson",
                data: {
                    type: "Feature",
                    geometry: {
                        type: geometryType,
                        coordinates: [lngLatLists],
                    },
                },
            });
            map.value.addLayer({
                id: 'zone-' + id,
                type: "fill",
                source: 'zone-' + id,
                layout: {},
                paint: {
                    "fill-color": color,
                    "fill-opacity": opacity,
                },
            });
        });

        zones.value.push({
            layerID: 'zone-' + id,
        })

        toggleLayer('zone-' + id, false);
    };

    const setZonesDisplay = (displayStatus = true) => {
        for (const zone of zones.value) {
            toggleLayer(zone.layerID, displayStatus);
        }

        showZones.value = displayStatus;
    };

    const toggleLayer = (layer, toggle) => {
        map.value.on('styledata', () => {
            if (!map.value.getLayer(layer)) return;
            if (toggle) {
                map.value.setLayoutProperty(layer, 'visibility', 'visible');
            } else {
                map.value.setLayoutProperty(layer, 'visibility', 'none');
            }
        })
    }

    const initZones = (mapBoxInstance) => {
        map.value = mapBoxInstance;
    }

    return {
        addZone,
        initZones,
        toggleLayer,
        setZonesDisplay
    };
}