import Service from "@/services/TwitterService";

// export action name
export const PULL = "twitter/pull";

// initial state
const state = () => ({
    all: [],
    meta: {},
});

// getters
const getters = {};

// actions
const actions = {
    [PULL]({ commit }, data) {
        commit('setShowLoader', true, { root: true });
        return Service.index(data).then((res) => {
            commit(PULL, res);
            return res;
        }).finally(() => {
            commit('setShowLoader', false, { root: true });
        });
    },
};

// mutations
const mutations = {
    [PULL](state, { data, meta }) {
        state.all = [...state.all, ...data];
        state.meta = Object.assign(state.meta, meta);
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
