// import Service from "@/services/ExampleService";

// export action name
// export const ALL = "example/all";

// initial state
const state = () => ({
    // all: []
});

// getters
const getters = {};

// actions
const actions = {
    // [ALL]({ commit }, data) {
    //     return Service.index(data).then((res) => {
    //         commit(ALL, res);
    //         return res;
    //     });
    // },
};

// mutations
const mutations = {
    // [ALL](state, { data, meta }) {
    //     state.all = data;
    //     state.meta = Object.assign(state.meta, meta);
    // }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
