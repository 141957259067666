const query = {
    mode: {
        default: 'default',
        evacuation: 'evacuation',
        risk: 'risk',
        waterlevel: 'water-level'
    },
    action: {
        shelter: "shelter",
        congestion: "congestion",
        waterLevel: "water-level",
        tsunami: "tsunami",
        earthquake: "earthquake",
        storm: 'storm',
        locate: "locate",
        risk: "risk",
        flood: "flood",
        liquefaction: "liquefaction"
    }
}

export default query;