import http from "@/axios";

/**
 * @author Phirum Seng | <s-phirum@beniten.com>
 */
export default class BaseService {
    static get METHOD_GET() {
        return "GET";
    }

    static get METHOD_POST() {
        return "POST";
    }

    static get METHOD_PUT() {
        return "PUT";
    }

    static get METHOD_DELETE() {
        return "DELETE";
    }

    constructor(prefix, baseURL = null) {
        this.prefix = prefix;
        this.baseURL = baseURL;
    }

    index(data) {
        return this.performRequest(BaseService.METHOD_GET, "", data);
    }

    show(id, data = {}) {
        return this.performRequest(BaseService.METHOD_GET, id, data);
    }

    store(data, headers = {}, isShowLoading = true) {
        return this.performRequest(
            BaseService.METHOD_POST,
            "",
            data,
            headers,
            isShowLoading
        );
    }

    update(id, data) {
        return this.performRequest(BaseService.METHOD_PUT, id, data);
    }

    destroy(id, data) {
        return this.performRequest(BaseService.METHOD_DELETE, id, data);
    }

    performRequest(method, url, data = {}, headers = {}, isShowLoading = true) {
        let endPoint = this.prefix + (url ? "/" + url : "");
        let options = {
            method,
            url: endPoint,
            data,
            headers,
            is_show_loading: isShowLoading,
            baseURL: this.baseURL,
        };

        options[
            method.toUpperCase() === BaseService.METHOD_GET ? "params" : "data"
        ] = data;
        return http(options);
    }
}
