export default {
    propertyToArray: (target, source = null, reverse = false) => {
        return Object.keys(source || target).reduce((obj, key) => {
            // Manipulate target
            obj[key] = !obj[key]
                ? obj[key]
                : Array.isArray(obj[key])
                ? obj[key]
                : [obj[key]];
            if (source && source[key]) {
                // Manipulate source
                obj[key] = (obj[key] || []).concat(
                    (Array.isArray(source[key])
                        ? source[key]
                        : [source[key]]
                    ).filter(
                        (src) =>
                            !(obj[key] || []).some(
                                (tar) => tar.toString() === src.toString()
                            )
                    )
                );
            }
            // Reverse property values
            if (reverse && obj[key]) {
                obj[key].reverse();
            }
            return obj;
        }, Object.assign({}, target));
    },
    recursivePluckToArray: function recursivePluckToArray(
        items,
        child,
        select = null
    ) {
        if (Array.isArray(items)) {
            return [].concat(
                ...items.map((item) =>
                    recursivePluckToArray(item, child, select)
                )
            );
        }
        if (items[child] && items[child].length > 0) {
            return [items].concat(
                recursivePluckToArray(items[child], child, select)
            );
        }
        if (!select || (Array.isArray(select) && select.length < 1)) {
            return [items];
        }

        select = Array.isArray(select) ? select : [select];
        return [
            select.reduce((res, s) => {
                res[s] = items[s] || null;
                return res;
            }, {}),
        ];
    },
    deepEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = this.isObject(val1) && this.isObject(val2);
            if (
                (areObjects && !this.deepEqual(val1, val2)) ||
                (!areObjects && val1 !== val2)
            ) {
                return false;
            }
        }
        return true;
    },
    isObject(object) {
        return object != null && typeof object === "object";
    },
    isEmpty(obj) {
        return Object.keys(obj).length === 0;
    },
    isNotEmpty(obj) {
        return !this.isEmpty(obj);
    },
    extendObject(target, ...sources) {
        const finalSource = sources.reduce((finalSrc, src) => Object.assign(finalSrc, src), {});
        return Object.keys(finalSource).reduce((tar,  key) => {
            if (!tar[key]) {
                tar[key] = finalSource[key];
            }
            return tar;
        }, target)
    }
};
