export default {
    toKebabCase: (str) => {
        return str
            .replace(/([a-z])([A-Z])/g, "$1-$2")
            .replace(/\s+/g, "-")
            .toLowerCase();
    },
    isNumeric: (value) => {
        return /^-?\d+$/.test(value);
    },
};
