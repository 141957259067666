

import { ref } from "vue";
import mapboxgl from "mapbox-gl";

export default function mapBoxMarker() {
    let markers = ref([]);
    let showMarkers = ref(true);
    let map = ref(null);

    const addMarker = (id, lng, lat, markerIcon = "marker-shelter") => {
        const el = document.createElement("div");

        el.className = markerIcon;
        
        const marker = new mapboxgl.Marker(el, { anchor: "bottom" })
            .setLngLat([lng, lat])
            .addTo(map.value);

        markers.value[id] = {
            element: marker.getElement(),
            position: [lng, lat]
        }
    }

    const isShowMarkers = () => {
        return showMarkers.value;
    }

    const registerMarkerClick = (id, event = () => {}) => {
        markers.value[id].element.addEventListener("click", () => {
            event();
        });
    };

    const setMarkersDisplay = (displayStatus = true) => {
        const hideClass = "marker-hide";
        for (const markerKey in markers.value) {
            const marker = markers.value[markerKey];
            if (displayStatus && marker.element.classList.contains(hideClass)) {
                marker.element.classList.remove(hideClass);
            } else if (!marker.element.classList.contains(hideClass)) {
                marker.element.classList.add(hideClass);
            }
        }

        showMarkers.value = displayStatus;
    };

    const setAllMarkersUnactive = () => {
        const activeClass = "marker-active";
        for (const markerKey in markers.value) {
            const marker = markers.value[markerKey];
            if (marker.element.classList.contains(activeClass)) {
                marker.element.classList.remove(activeClass);
            }
        }
    }

    const setMarkerActive = (markerID, activeStatus = true) => {
        const activeClass = "marker-active";
        const zoom = 15;

        setAllMarkersUnactive();
        
        if (!activeStatus) {
            return;
        }

        const marker = markers.value[markerID];
        if (!marker.element.classList.contains(activeClass)) {
            marker.element.classList.add(activeClass);
        }

        if (map.value.getZoom() < zoom) {
            map.value.flyTo({
                center: marker.position,
                zoom: zoom
            })
        } else {
            map.value.flyTo({
                center: marker.position,
            })
        }
    }

    const initMarkers = (mapBoxInstance) => {
        map.value = mapBoxInstance;
    }

    return {
        addMarker,
        isShowMarkers,
        registerMarkerClick,
        setMarkersDisplay,
        setMarkerActive,
        initMarkers
    };
}