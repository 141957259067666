

import { ref } from "vue";
import mapboxgl from "mapbox-gl";

export default function mapBoxPopup(mapBox) {
    let popups = ref([]);
    let map = ref(mapBox);
    let showPopups = ref(true);

    const addPopup = (long, lat, text) => {
        const popUpElement = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                maxWidth: "auto"
            })
            .setLngLat([long, lat])
            .setHTML('<div class="popup-container"><div class="popup-warning"></div><div class="popup-text">' + text + '</div></div>')
            .addTo(map.value)
            .getElement();

        popups.value.push({
            long,
            lat,
            text,
            element: popUpElement
        })
    }

    const registerPopupOnClick = (index, event = () => {}) => {
        if (index > popups.value.length - 1) return;
        const popUp = popups.value[index];

        popUp.element.addEventListener('click', () => {
            event();
        });
    }

    const setPopupsDisplay = (displayStatus = true) => {
        const hideClass = "popup-hide";

        for (const popup of popups.value) {
            for (const child of popup.element.children) {
                if (displayStatus && child.classList.contains(hideClass)) {
                    child.classList.remove(hideClass);
                } else if (!child.classList.contains(hideClass)) {
                    child.classList.add(hideClass);
                }
            }
        }

        showPopups.value = displayStatus;
    };

    const isShowPopups = () => {
        return showPopups.value;
    }

    const initPopups = (mapBoxInstance) => {
        map.value = mapBoxInstance;
    }

    return {
        initPopups,
        addPopup,
        isShowPopups,
        setPopupsDisplay,
        registerPopupOnClick,
    };
}