<template>
    <div id="map"></div>
    <modal ref="locatePermissionModal" :content="$t('map.permissionNotify')"></modal>
</template>

<script>
import mapboxgl from "mapbox-gl";
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl-controls/lib/controls.css";
import mapBoxControl from "@/composables/mapBoxComponents/mapBoxControl";
import mapBoxMarker from "@/composables/mapBoxComponents/mapBoxMarker";
import mapBoxZone from "@/composables/mapBoxComponents/mapBoxZone";
import mapBoxPopup from "@/composables/mapBoxComponents/mapBoxPopup";
import mapBoxLine from "@/composables/mapBoxComponents/mapBoxLine";
import { onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import query from "@/constants/query";

export default {
    name: "MapBox",
    props: {
        center: {
            type: Array,
            default() {
                return [
                    process.env.VUE_APP_MAPBOX_LONG || 139.7613669,
                    process.env.VUE_APP_MAPBOX_LAT || 35.6547533,
                ];
            },
        },
        zoom: {
            type: Number,
            default: 15,
        },
        mapStyle: {
            type: String,
            default: "mapbox://styles/mapbox/streets-v11",
        },
    },
    setup(props) {
        const map = ref(null);
        const route = useRoute();
        let controlList = ref({});
        let isLocated = ref(false);
        let currentPosition = ref(null);
        let locatePermissionModal = ref(null);
        let geolocate = ref(null);

        const {
            listControl,
            shelterControl,
            locateControl,
            tsunamiControl,
            twoGroupControl,
            threeGroupControl,
        } = mapBoxControl();

        const marker = mapBoxMarker();

        const zone = mapBoxZone();
        const popup = mapBoxPopup();
        const line = mapBoxLine();
        

        const isRiskMap = () => {
            return route.query.mode === query.mode.risk || route.query.mode === query.mode.waterlevel;
        }

        const isEvacuationMap = () => {
            return route.query.has === query.mode.evacuation;
        }

        const init = () => {
            mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCCESS_TOKEN;

            map.value = new mapboxgl.Map({
                container: "map",
                style: props.mapStyle,
                zoom: props.zoom,
                attributionControl: false,
                center: props.center,
            });

            map.value.addControl(new MapboxLanguage({ defaultLanguage: 'ja' }));

            if (isEvacuationMap()) {
                map.value.addControl(twoGroupControl, "top-right");
                map.value.addControl(listControl, "bottom-left");

                map.value.addControl(shelterControl, "top-right");

                controlList.value = {
                    twoGroupControl,
                    listControl,
                    shelterControl,
                };
            } else if (isRiskMap()) {
                map.value.addControl(threeGroupControl, "top-right");
                map.value.addControl(listControl, "bottom-left");
                map.value.addControl(tsunamiControl, "bottom-left");

                controlList.value = {
                    threeGroupControl,
                    tsunamiControl,
                    listControl
                };
            } else {
                map.value.addControl(twoGroupControl, "top-right");
                map.value.addControl(listControl, "bottom-left");

                controlList.value = {
                    twoGroupControl,
                    listControl,
                };
            }

            geolocate.value = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true,
                },
                trackUserLocation: true,
            });

            geolocate.value.on('geolocate', (position) => {
                currentPosition.value = position;
            });

            map.value.addControl(locateControl, "bottom-right");
            map.value.addControl(geolocate.value);

            locateControl.registerEventOnClick(async () => {
                locateControl.showLoading();

                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(() => {
                        if (!isLocated.value) { 
                            geolocate.value.trigger();
                        }
                        
                        isLocated.value = true;
                        locateControl.showLoading(false);
                    }, () => {
                        locateControl.showLoading(false);
                        locatePermissionModal.value.onOpen();
                    });
                }

                if (currentPosition.value) {
                    map.value.flyTo({
                        center: [
                            currentPosition.value.coords.longitude,
                            currentPosition.value.coords.latitude,
                        ],
                        zoom: props.zoom
                    });
                    locateControl.showLoading(false);
                }
            });
        };

        onMounted(() => {
            init();
            marker.initMarkers(map.value);
            zone.initZones(map.value);
            popup.initPopups(map.value);
            line.initLine(map.value);

            zone.addZone('default', [props.center]);
            zone.toggleLayer('zone-default', true);
        });

        return {
            control: controlList,
            popup,
            marker,
            zone,
            line,
            locateControl,
            isLocated,
            currentPosition,
            map,
            locatePermissionModal,
            geolocate: computed(() => geolocate.value)
        };
    },
};
</script>

<style lang="scss" scoped>
#map {
    height: 100%;
    position: absolute;
    width: 100%;
}
</style>
