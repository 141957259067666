const MUTATIONS = [];

const mutations = MUTATIONS.reduce((res, m) => {
    res[m] = (state, val) => (state[m] = val);
    return res;
}, {
    setShowLoader(state, isShowLoader) {
        state.isShowLoader = isShowLoader
    }
});

export default mutations;
