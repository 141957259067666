

import { ref } from "vue";
import mapboxgl from "mapbox-gl";

export default function mapBoxLine() {
    let lines = ref([]);
    let showLines = ref(false);
    let map = ref(null);

    const addLine = (line, color="#FF1212", size=3) => {
        map.value.on('style.load', () => {
            map.value.addSource('route-' + line.id, {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': line.coordinates
                    }
                }
            });
            map.value.addLayer({
                'id': 'route-outline-' + line.id,
                'type': 'line',
                'source': 'route-' + line.id,
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': color,
                    'line-width': size * size,
                    'line-opacity': 0.27
                }
            });
            map.value.addLayer({
                'id': 'route-' + line.id,
                'type': 'line',
                'source': 'route-' + line.id,
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': color,
                    'line-width': size,
                    'line-opacity': 0.67
                }
            });
        });

        const htmlText = `<div class="popup-container ${
                line.popup.containerClass || ""
            }"><div class="popup-icon-${line.popup.icon || "warning"} ${
                line.popup.customClass || ""
            }"></div><div class="popup-text">${line.popup.text}</div></div>`;

        const marker = line.coordinates.length === 1 ? getNewMarker(line.coordinates[0]) : null;

        let popUpElement = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            maxWidth: "auto",
            offset: 18
        })
            .setLngLat(line.popup.center)
            .setHTML(htmlText)
            .addTo(map.value);

        lines.value[line.id] = {
            lineID: 'route-' + line.id,
            popupElement: popUpElement.getElement(),
            bubbleData: line.bubble,
            marker: marker ? marker.getElement() : null
        }
        
        setLineDisplay(line.id);
    }

    const getNewMarker = (lngLat) => {
        const el = document.createElement('div');
            el.className = 'marker-pin';

        return new mapboxgl.Marker(el, { anchor: "bottom" })
            .setLngLat(lngLat)
            .addTo(map.value);
    }

    const isShowLines = () => {
        return showLines.value;
    }

    const registerOnPopupClick = (index, event = () => {}) => {
        const line = lines.value[index];
        if (!line.popupElement) {
            return;
        }
        lines.value[index].popupElement.addEventListener("click", event);

        if (!lines.value[index].marker) {
            return;
        }
        lines.value[index].marker.addEventListener("click", event);
    };

    const setLinesDisplay = (displayStatus = true) => {
        const hideClass = "hide";
        for (const line of lines.value) {
            for (const child of line.popupElement.children) {
                if (displayStatus && child.classList.contains(hideClass)) {
                    child.classList.remove(hideClass);
                } else if (!child.classList.contains(hideClass)) {
                    child.classList.add(hideClass);
                }
            }

            if (line.marker) {
                if (displayStatus && line.marker.classList.contains(hideClass)) {
                    line.marker.classList.remove(hideClass);
                } else if (!line.marker.classList.contains(hideClass)) {
                    line.marker.classList.add(hideClass);
                }
            }
            
            toggleLayer(line.lineID, displayStatus)
        }

        showLines.value = displayStatus;
    };

    const setLineDisplay = (id, toggle = false) => {
        const hideClass = "hide";
        const line = lines.value[id];
        for (const child of line.popupElement.children) {
            if (toggle && child.classList.contains(hideClass)) {
                child.classList.remove(hideClass);
            } else if (!child.classList.contains(hideClass)) {
                child.classList.add(hideClass);
            }
        }

        if (line.marker) {
            if (toggle && line.marker.classList.contains(hideClass)) {
                line.marker.classList.remove(hideClass);
            } else if (!line.marker.classList.contains(hideClass)) {
                line.marker.classList.add(hideClass);
            }
        }

        toggleLayer(line.lineID, toggle);
        toggleLayer('route-outline-' + id, toggle);
    }

    const toggleLayer = (layer, toggle) => {
        map.value.on('styledata', () => {
            if (!map.value.getLayer(layer)) return;
            if (toggle) {
                map.value.setLayoutProperty(layer, 'visibility', 'visible');
            } else {
                map.value.setLayoutProperty(layer, 'visibility', 'none');
            }
        })
    }

    const initLine = (mapBoxInstance) => {
        map.value = mapBoxInstance;
    }

    return {
        addLine,
        isShowLines,
        registerOnPopupClick,
        setLinesDisplay,
        setLineDisplay,
        initLine
    };
}