<template>
    <div :class="'bubble-container fade ' + (bubbleClass) + (isClosed ? '' : ' show')">
        <div class="bubble-header">
            <slot name="title">
                <div v-if="size === 'lg'" class="font-sm" v-html="title"></div>
                <div v-else>{{ $t(title) }}</div>
            </slot>
            <div class="bubble-header-cross" @click="onClose">
                <i class="fa-solid fa-xmark"></i>
            </div>
        </div>
        <div class="bubble-subtitle flex flex-row" v-if="subtitle">
            <img class='bubble-icon-pin' src="/img/icons/icon_pin.svg" />
            <label class="bubble-text">{{ $t(subtitle) }}</label>
        </div>
        <div :class="'bubble-body ' + (size === 'lg' ? size : '')">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
import { watch, ref, computed } from '@vue/runtime-core';
export default {
    name: "BubblePopup",
    props: {
        title: { type: String, default: "" },
        subtitle: { type: String, default: "" },
        class: { type: String, default: "" },
        size: {type: String, default: ''}
    },
    emits: ['on-close-event'],
    setup(props, {emit}) {
        let isClosed = ref(true);
        let bubbleClass = ref('');

        watch(() => isClosed.value, () => {
            if (isClosed.value) {
                emit('on-close-event');
            }
        })

        watch(() => props.class, () => {
            bubbleClass.value = props.class || '';
        })

        return {
            bubbleClass: computed(() => bubbleClass.value),
            isClosed
        }

    },
    methods: {
        onClose() {
            this.isClosed = true;
        },
        onOpen(canClosed = false) {
            this.isClosed = canClosed ? !this.isClosed : canClosed;
        }
    },
};
</script>
