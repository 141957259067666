import { createStore, createLogger } from "vuex";
import file from "@/mixins/file";

// Root
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

// Load all modules
const modules = require.context("./modules", true, /\.(js)$/i);

const debug = process.env.NODE_ENV !== "production";

export default createStore({
    modules: modules.keys().reduce((res, k) => {
        res[file.getFileNameFromPath(k)] = modules(k).default;
        return res;
    }, {}),
    state,
    getters,
    mutations,
    actions,
    strict: debug,
    plugins: debug ? [createLogger()] : [],
});
