import { createI18n } from "vue-i18n/index";

import ja from "./locales/ja";

const i18nData = {
    ja
};

const i18n = createI18n({
    //set default language here
    locale: process.env.VUE_APP_LOCALE ?? "ja",
    fallbackLocale: "ja",
    messages: i18nData,
});

export default i18n;
