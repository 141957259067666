class ButtonControl {
    constructor(options = null) {
        this._options = options || {
            buttonStyle: 'square',
            text: '',
            iconClass: '',
            containerClass: '',
            canActive: false,
            eventHandler: () => {}
        };
        this._displayStatus = false;
    }

    showLoading(status = true) {
        const imgElement = this._stylesElement.children[0];
        if (imgElement.tagName !== 'IMG') {
            return;
        }

        if (status) {
            imgElement.src = require("@assets/img/icons/loading_gif.svg");
        } else {
            imgElement.src = this._options.src;
        }
    }

    onAdd(map) {
        this._map = map;

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl ' + this._options.containerClass || '';

        this._stylesElement = document.createElement('button');
        this._stylesElement.classList = 'ctrl-btn ctrl-btn--' + this._options.buttonStyle;

        if (this._options.iconClass) {
            const iconElement = document.createElement('img');
            iconElement.className = 'ctrl-icon ' + this._options.iconClass || '';
            this._stylesElement.appendChild(iconElement);
        }

        if (this._options.src) {
            const imgElement = document.createElement('img');
            imgElement.className = 'ctrl-img';
            imgElement.src = this._options.src;
            this._stylesElement.appendChild(imgElement);
        }

        if (this._options.text) {
            const textElement = document.createElement('div');
            textElement.className="ctrl-text";
            textElement.textContent = this._options.text;
            this._stylesElement.appendChild(textElement);
        }

        this.registerEventOnClick(this._options.eventHandler);
        this._container.appendChild(this._stylesElement);

        return this._container;
    }

    getDefaultPosition() {
        return 'top-right';
    }

    registerEventOnClick(eventHandler = () => {}) {
        this._stylesElement.onclick = () => {
            if (this._options.canActive) {
                this.setActiveButton(true);
            }
            
            eventHandler();
        }
    }

    setDisplayButton(displayStatus = true) {
        if (displayStatus) {
            if (this._stylesElement.classList.contains('hide')) {
                this._stylesElement.classList.remove('hide');
            }
        } else {
            if (!this._stylesElement.classList.contains('hide')) {
                this._stylesElement.classList.add('hide');
            }
        }

        this._displayStatus = displayStatus;
    }

    setActiveButton(activeStatus) {
        if (activeStatus) {
            if (!this._stylesElement.classList.contains('ctrl-btn-active')) {
                this._stylesElement.classList.add('ctrl-btn-active')
            }
        } else {
            if (this._stylesElement.classList.contains('ctrl-btn-active')) {
                this._stylesElement.classList.remove('ctrl-btn-active');
            }
        }
    }

    isShowButton() {
        return this._displayStatus;
    }

    trigger() {
        this._stylesElement.click();
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

export default ButtonControl;
