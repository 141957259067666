import { createApp } from "vue";
import mixins from "@/mixins";
import store from "@/store";
import router from "@/router";
import i18n from "@/i18n";
import registerComponents from "@/components";
import App from "@/App.vue";
import scss from "@/assets/scss/app.scss";

const app = createApp(App)
    .mixin(mixins)
    .use(store)
    .use(i18n)
    .use(router)
    .use(scss);
registerComponents(app);

export default app.mount("#app");
