import { useRoute, useRouter } from "vue-router";

export default function useQuery() {
    const router = useRouter();
    const route = useRoute();

    const pushQuery = (paramObj = {}) => {
        router.push({
            ...route.query,
            ...paramObj
        })
    };

    const replaceQuery = (paramObj = {}) => {
        router.replace({
            query: {
                ...route.query,
                ...paramObj
            }
        })
    }

    const hasQuery = (param) => {
        return !!route.query[param];
    }

    const getQuery = (param) => {
        return route.query[param];
    }

    const createQueryIfNotExist = (key, value, excludeValue = []) => {
        if (!route.query[key]) {
            router.push({
                query: {
                    ...route.query,
                    [key]: value
                }
            });
            return;
        }

        let queryList = route.query[key].split(',');

        if (queryList.includes(value)) {
            return;   
        }

        queryList.push(value);

        let result = Object.assign({}, {
            ...route.query,
            [key]: (queryList).join(',')
        });

        if (excludeValue.length > 0) {
            excludeValue.filter(item => {
                if (result[key].split(',').includes(item)) {
                    result = {
                        ...result,
                        [key]: (result[key].split(',').filter(value => value !== item)).join(',')
                    };
                }
            })
        }

        router.push({
            query: result
        });

    }

    const checkIfQueryExisted = (key, value) => {
        if (!route.query[key]) {
            return false;
        }

        const queryParams = route.query[key];

        if (!Array.isArray(queryParams)) {
            return queryParams.split(',').includes(value);
        }

        router.replace(
            {
                query: {
                    ...route.query,
                    [key]: queryParams.join(',')
                }
            }
        )

        return queryParams.includes(value);
    }

    const removeQuery = (key, value) => {
        if (!route.query[key]) {
            return false;
        }

        let result = route.query[key].split(',');

        if (!result.includes(value)) {
            return;
        }

        result = {
            ...route.query,
            [key]: (result.filter(item => item !== value)).join(',')
        };

        if (!result[key]) {
            delete result[key];
        }

        router.replace({
            query: result
        });
    }

    return {
        pushQuery,
        replaceQuery,
        hasQuery,
        getQuery,
        removeQuery,
        createQueryIfNotExist,
        checkIfQueryExisted
    };
}
