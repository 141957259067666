import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        redirect: { name: "map" },
    },
    {
        path: "/map",
        name: "map",
        component: () => import(/* webpackChunkName: "Map" */ "@/views/Map.vue"),
    },
    {
        path: "/twitter",
        name: "twitter",
        component: () => import(/* webpackChunkName: "Twitter" */ "@/views/Twitter.vue"),
  },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
