<template>
    <div :class="'emergency-container ' + color " v-if="!isClosed">
        <slot name="content"><div>{{ text }}</div></slot>
    </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core';
export default {
    name: "EmergencyPopup",
    props: {
        text: { type: String, default: "" },
        color: { type: String, default: "bg-primary"}
    },
    setup() {
        let isClosed = ref(false);

        onMounted(() => {
        }) ;

        return {
            isClosed
        }

    },
    methods: {
        onClose() {
            this.isClosed = true;
        },
        onOpen(canClosed = false) {
            this.isClosed = canClosed ? !this.isClosed : canClosed;
        }
    },
};
</script>
