<template>
    <transition name="modal">
        <div class="modal" v-if="!isClosed">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header" v-if="title">
                        <slot name="header">
                            {{ title }}
                        </slot>
                    </div>

                    <div class="modal-body" v-if="content">
                        <slot name="body">
                            <div v-text="content"></div>
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <button
                                class="btn"
                                @click="onClose"
                            >
                                OK
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { ref } from '@vue/reactivity';
export default {
    name: "Modal",
    props: {
        title: {
            type: String,
            default: null,
        },
        content: {
            type: String,
            default: null,
        },
    },
    setup() {
        const isClosed = ref(true);

        const onOpen = () => {
            isClosed.value = false;
        }

        const onClose = () => {
            isClosed.value = true;
        }

        return {
            onOpen,
            onClose,
            isClosed
        }
    },
};
</script>
<style lang="scss" scoped>
.modal {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    touch-action: none;

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
        .modal-container {
            width: 300px;
            margin: 0px auto;
            padding: 20px 30px;
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
            font-family: "Hiragino Kaku Gothic ProN";
            font-weight: 300;

            .modal-body {
                margin: 20px 0;
            }

            .modal-footer {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}
</style>